import { useEffect, useState } from 'react';

import { LinkAccountInput } from '../../apollo/account-api/types/global';
import { useUser } from '../../context/AuthContext';
import { useDashboardBff } from '../useDashboardBff';

export type Response = {
  accounts: LinkAccountInput[];
  error?: Error;
  loading: boolean;
};

export type FetchAccountsInput = { accountId?: string | null; name?: string; programId?: string | null };

export default function useFetchAccounts({ accountId, name, programId }: FetchAccountsInput): Response {
  const { accountId: userAccountId } = useUser();
  const { apiClient: dashboardBffApiClient } = useDashboardBff();
  const [accounts, setAccounts] = useState<LinkAccountInput[]>([]);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // if programId is defined as null, we avoid making call
    if (programId === null) {
      setAccounts([]);
      setError(null);
      return;
    }

    const url = '/accounts/search?';

    dashboardBffApiClient()
      .get<LinkAccountInput[]>(url, {
        params: { accountId: programId || name ? undefined : (accountId ?? userAccountId), name, programId },
      })
      .then(({ data }) => {
        setAccounts(data);
        setError(null);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }, [accountId, userAccountId, name, programId]);

  return { accounts, error, loading };
}
